
* {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  quotes: "“" "”";
  color: $white;
}

a {
  color: inherit;
}

h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  color: $white;

  strong {
    font-weight: 600;
  }
}

h2 {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: $wheat;
}