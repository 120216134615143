@import "variables";
@import "typography";
@import "layout";
@import "photo";
@import "header";
@import "card";
@import "section";


* {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background: $black;
}

@-webkit-keyframes startup {
  0% {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
  }

  100% {
    filter: none;
    -webkit-filter: none;
    opacity: 1;
  }
}

@-moz-keyframes startup {
  0% {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
  }

  100% {
    filter: none;
    -webkit-filter: none;
    opacity: 1;
  }
}

@-o-keyframes startup {
  0% {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
  }

  100% {
    filter: none;
    -webkit-filter: none;
    opacity: 1;
  }
}

@keyframes startup {
  0% {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
  }

  100% {
    filter: none;
    -webkit-filter: none;
    opacity: 1;
  }
}

body {
  -webkit-animation: startup 500ms;
  -moz-animation: startup 500ms;
  -o-animation: startup 500ms;
  animation: startup 500ms;
}