
.Photo {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 780px;
  z-index: -1;
  background: url("/static/photo.jpg") no-repeat top right;
  background-size: contain;
  transition: filter .2s ease-in-out, opacity .2s ease-in-out;

  img {
    display: none;
  }

  @media (max-width: 1230px) {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
  }

  @media (max-width: 790px) {
    opacity: .1;
  }

  @media (max-width: 680px) {
    display: none;
  }

  @media (min-height: 1025px) {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    opacity: .2;
    max-width: none;
  }
}
