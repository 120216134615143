

.Sections {

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.Section {
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 48px;

  h2 {
    padding-bottom: 4px;
  }

  &__link {
    display: block;

    strong {
      font-weight: bold;
    }

    &--muted {
      color: $grey;
    }
  }

  &__muted {
    padding-top: 24px;
    color: $grey;
  }

  &__line {

  }

  &__address {
    display: block;
    font-style: normal;
  }
}