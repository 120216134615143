

.Header {
  margin-bottom: 56px;

  @media (max-width: 510px) {
    margin-bottom: 48px;
  }

  &__title {
    padding-bottom: 4px;
  }

  &__lead {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: $wheat;

    @media (max-width: 510px) {

      br {
        display: none;
      }
    }
  }
}