
.Container {
  display: block;
  padding: 128px 0;
  margin: auto;
  max-width: 743px;
  transition: all .2s ease-in-out;

  @media (max-width: 1660px) {
    margin: 0 256px;
  }

  @media (max-width: 1400px) {
    margin: 0 128px;
  }

  @media (max-width: 1158px) {
    margin: auto;
    max-width: 999px;
    padding: 64px;
  }

  @media (max-width: 510px) {
    padding: 40px 24px;
  }
}

.Column {
  float: left;
  max-width: 215px;
  margin-right: 48px;

  @media (max-width: 790px) {
    float: none;
    max-width: none;
    margin: 0;
  }

  &--large {
    max-width: 480px;
    margin-right: 0;

    @media (max-width: 790px) {
      max-width: none;
    }
  }
}